import * as React from 'react';
import PersonalBlogWrapper from './style';
import Posts from './posts';
import 'swiper/css/bundle';
import { OurGamesCol, OurGamesPageTitle, OurGamesRow, OurGamesWrapper } from '../ourgames/style';
import Masonry from 'react-masonry-component';
import Button from '../../components/button/button';
import { Link } from 'gatsby';
import scrollTo from 'gatsby-plugin-smoothscroll';
import Card from '../../components/card/card';

import aaImage from "../../images/aa.png";
import eirImage from '../../images/eir.jpg';
import hhhImage from '../../images/hhh.png';
import rwnwImage from '../../images/rwnw.jpg';

type PersonalBlogProps = {};

const PersonalBlog: React.FunctionComponent<PersonalBlogProps> = ({
  ...props
}) => {

  const subButton = (e: any) => {
    e.preventDefault();
    scrollTo('.newsletter');
  };
  return (
    <PersonalBlogWrapper {...props}>
      
    <OurGamesWrapper>
      <Link to={"our-games"} onClick={subButton} className="subscribe-button"><Button title="Subscribe to our mailing list">Subscribe to our mailing list</Button></Link>
      <OurGamesPageTitle>
        <h2>Our Games</h2>
      </OurGamesPageTitle>
        <Masonry className="showcase">
          <OurGamesRow>
            <OurGamesCol className='fourth'>
              <Card
                title="Ale Abbey"
                excerpt="A serene pixel-based Brewery Tycoon set in a late Middle Ages Monastery."
                url="https://www.indiedb.com/games/ale-abbey-in-hops-we-trust"
                callToAction = "Check it out"
                image={aaImage}
              />
            </OurGamesCol>
            <OurGamesCol className='fourth'>
              <Card 
                title="Empires in Ruins"
                excerpt="An antihero's epic set in a grim, corrupt world, fusing Tower Defence/4X elements."
                url="https://store.steampowered.com/app/604510/Empires_in_Ruins/"
                callToAction="Check it out"
                isStatic={true}
                image={eirImage}
                steamUrl={"https://store.steampowered.com/app/604510/Empires_in_Ruins/"}
              />
            </OurGamesCol>
            <OurGamesCol className='fourth'>
              <Card
                title="Headbangers in Holiday Hell"
                excerpt="Set Santa's helpers straight in a Metal, 90s reeking 'Zombies Ate my Neighbours'."
                url="https://store.steampowered.com/app/1296700/Headbangers_in_Holiday_Hell/"
                callToAction="Check it out"
                isStatic={true}
                image={hhhImage}
                steamUrl={"https://store.steampowered.com/app/1296700/Headbangers_in_Holiday_Hell/"}
              />
            </OurGamesCol>
            <OurGamesCol className='fourth'>
              <Card
                title="RazerWire:NanoWars"
                excerpt="An addictive fast-paced Roguelite x Bullet Hell in space... Tight controls; Pixels; Lasers!"
                url="https://store.steampowered.com/app/788930/RazerwireNanowars/"
                callToAction = "Check it out"
                image={rwnwImage}
                steamUrl={"https://store.steampowered.com/app/788930/RazerwireNanowars/"}
                switchUrl={"https://www.nintendo.com/store/products/razerwire-nanowars-switch/"}
                xboxUrl={"https://www.xbox.com/en-GB/games/store/razerwire-nanowars/9phg0hcq0ntd/"}
              />
            </OurGamesCol>
          </OurGamesRow>
        </Masonry>
      {/* <OurGamesButton>
          <Link to={"our-games"}><Button title="View all">View all games</Button></Link>
      </OurGamesButton> */}
      <Posts/>
    </OurGamesWrapper>

    </PersonalBlogWrapper>
  );
};

export default PersonalBlog;
