import * as React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import SocialProfile from '../../components/social-profile/social-profile';
import FooterWrapper, {
  FooterCol,
  Logo,
  Infos,
  FooterTitle,
  FooterContent,
  Menu,
  FooterWrapperContainer,
  SargeImage,
  SargeImageContainer,
} from './footer.style';
import LogoImage from '../../images/logo_horizontal.png';
import sargeImage from '../../images/sarge.png';

import {
  IoLogoFacebook,
  IoLogoTwitter,
  IoLogoInstagram,
  IoLogoLinkedin,
  IoLogoYoutube,
} from 'react-icons/io';
import { FaDiscord } from 'react-icons/fa';

const SocialLinks = [
  {
    icon: <FaDiscord color="#828080" />,
    url: 'https://discord.gg/eir',
    tooltip: '',
  },
  {
    icon: <IoLogoFacebook color="#828080" />,
    url: 'https://www.facebook.com/hammerandravens',
    tooltip: '',
  },
  {
    icon: <IoLogoInstagram color="#828080" />,
    url: 'https://www.instagram.com/ham_rav/',
    tooltip: '',
  },
  {
    icon: <IoLogoTwitter color="#828080" />,
    url: 'https://twitter.com/ham_rav',
    tooltip: '',
  },
  {
    icon: <IoLogoLinkedin color="#828080" />,
    url: 'https://www.linkedin.com/company/11022077',
    tooltip: '',
  },
  {
    icon: <IoLogoYoutube color="#8b6f66" />,
    url: 'https://www.youtube.com/c/HammerRavens',
    tooltip: '',
  },
];

type FooterProps = {
  children: React.ReactNode;
};

const Footer: React.FunctionComponent<FooterProps> = ({
  children,
  ...props
}) => {
  const Data = useStaticQuery(graphql`
    query {
      allMarkdownRemark {
        group(field: frontmatter___categories) {
          fieldValue
        }
      }
    }
  `);

  const Category = Data.allMarkdownRemark.group;

  return (
    <FooterWrapperContainer>
      <FooterWrapper {...props}>
        <FooterCol>
          <Logo>
            <Link to="/">
              <img src={LogoImage} alt="logo" />
            </Link>
          </Logo>

          <Infos>info@hammerandravens.com</Infos>
          {/* <Infos>+1-2345-6789-9</Infos> */}
          <br />
          <Infos>
            Copyright &copy;&nbsp;
            <a href="https://hammerandravens.com"> Hammer&RavensOÜ.</a>
          </Infos>
        </FooterCol>

        <FooterCol>
          <FooterTitle>Follow Us</FooterTitle>

          <SocialProfile items={SocialLinks} />
        </FooterCol>

        <FooterCol>
          <FooterTitle>Category</FooterTitle>

          <FooterContent>
            {Category.slice(0, 4).map((cat: any, index: any) => (
              <Menu key={index} to={`/category/${cat.fieldValue}`}>
                {cat.fieldValue}
              </Menu>
            ))}
          </FooterContent>
        </FooterCol>

        <FooterCol>
          <SargeImageContainer>
            <SargeImage src={sargeImage}/>
          </SargeImageContainer>
        </FooterCol>
      </FooterWrapper>
      <SargeImageContainer>
        <SargeImage className='sarge-outer' src={sargeImage}/>
      </SargeImageContainer>
    </FooterWrapperContainer>
  );
};

export default Footer;
