import React, { useState } from 'react';
import { Link } from 'gatsby';
import { IoIosClose, IoLogoYoutube } from 'react-icons/io';
import { DrawerProvider } from '../../components/drawer/drawer-context';
import SocialProfile from '../../components/social-profile/social-profile';
import Menu from './menu';
import Drawer from './drawer';
import SearchContainer from '../search/search';
import HeaderWrapper, {
  HeaderTop,
  TopBarWrapper,
  Logo,
  MenuWrapper,
  NavSearchWrapper,
  SearchCloseButton,
  NavSearchFromWrapper,
  SocialProfileWrapper,
} from './navbar.style';
import LogoImage from '../../images/logo.png';

import {
  IoLogoFacebook,
  IoLogoTwitter,
  IoLogoInstagram,
} from 'react-icons/io';

import {
  FaDiscord,
} from 'react-icons/fa';

type NavbarProps = {
  className?: string;
};

const MenuItems = [
  {
    label: 'Home',
    url: '/',
  },
  {
    label: 'Games',
    url: '/our-games',
  },
  {
    label: 'Blog',
    url: '/blog',
  },
  {
    label: 'About Us',
    url: '/about',
  },
];

const SocialLinks = [
  {
    icon: <FaDiscord color="#828080" />,
    url: 'https://discord.gg/eir',
    tooltip: '',
  },
  {
    icon: <IoLogoFacebook color="#828080" />,
    url: 'https://www.facebook.com/hammerandravens',
    tooltip: '',
  },
  {
    icon: <IoLogoInstagram color="#828080" />,
    url: 'https://www.instagram.com/ham_rav/',
    tooltip: '',
  },
  {
    icon: <IoLogoTwitter color="#828080" />,
    url: 'https://twitter.com/ham_rav',
    tooltip: '',
  },
  {
    icon: <IoLogoYoutube color="#8b6f66" />,
    url: 'https://www.youtube.com/c/HammerRavens',
    tooltip: '',
  },
];

const Navbar: React.FunctionComponent<NavbarProps> = ({
  className,
  ...props
}) => {
  const [state, setState] = useState({
    toggle: false,
    search: '',
  });

  const toggleHandle = () => {
    setState({
      ...state,
      toggle: !state.toggle,
    });
  };

  // Add all classs to an array
  const addAllClasses = ['header'];

  // className prop checking
  if (className) {
    addAllClasses.push(className);
  }

  return (
    <HeaderWrapper className={addAllClasses.join(' ')} {...props}>
      <HeaderTop>
        <TopBarWrapper>
          <Logo>
            <Link to="/">
              <img src={LogoImage} alt="logo" />
            </Link>
          </Logo>

          {/* <NavSearchButton
            type="button"
            aria-label="search"
            onClick={toggleHandle}
          >
            <IoIosSearch size="23px" />
          </NavSearchButton> */}
          <MenuWrapper>
            <Menu items={MenuItems} />
          </MenuWrapper>
          <SocialProfileWrapper>
            <SocialProfile items={SocialLinks} />
          </SocialProfileWrapper>

          <DrawerProvider>
            <Drawer items={MenuItems} socialLinks={SocialLinks} logo={LogoImage} />
          </DrawerProvider>
        </TopBarWrapper>
        <NavSearchWrapper className={state.toggle === true ? 'expand' : ''}>
          <NavSearchFromWrapper>
            <SearchContainer />
            <SearchCloseButton
              type="submit"
              aria-label="close"
              onClick={toggleHandle}
            >
              <IoIosClose />
            </SearchCloseButton>
          </NavSearchFromWrapper>
        </NavSearchWrapper>
      </HeaderTop>

      {/* <NavbarWrapper className='navbar'>
      </NavbarWrapper> */}
    </HeaderWrapper>
  );
};

export default Navbar;
